.sticky-wrapper {
  position: sticky;
  top: $headerH;
  z-index: 1;
  background-color: $color__black;

  &-library {
    top: $headerHBis !important;
  }

  /* .with-sidebar & {
    top: calc-rem(148)
  } */

  .with-sidebar & {
    top: calc-rem(158); // 158 heahderh + 32 padding-top
    margin-top: 0;
    padding: calc-rem(10) 0;
    // padding-top: 0;
  }

  .assets-total-count {
    font-size: calc-rem(32);
    line-height: 1;
    white-space: nowrap;
  }
}