.mobile-blocker {
    z-index: 999;
   // display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color__black;


    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;

    // @media (max-width: 1024px) {
    //     display: block;
    //     width: 100%;
    //     margin: 0;
    //     padding: 0;
    //     max-width: 100%;
    // }

    .row,
    .col-12 {
        height: 100%;
    }

    .visual {
        position: relative;
    }

    .gradient {
        position: absolute;
        top: 40px;
        left: 40px;
        width: 163px;
        height: 294px;
        padding: 119px 54px 119px 53px;
        border-radius: 7px;
        background-image: linear-gradient(209deg, $color__pink, $color__orange);
    }

    .picto, .icon {
        @extend .abs-center;
    }

    .picto {
        font-family: $font_secondary;
        font-size: calc-rem(32);
    }

    .icon {
        width: 56px;
        height: 56px;
    }

    .text-gradient {
        max-width: 335px;
        font-size: calc-rem(32);
        line-height: 1;
    }
}
