.custom-select-container {
  position: relative;
  width: 100%;

  select {
    width: 100%;
    height: 55px;
    padding: calc-rem(16) calc-rem(24);
    line-height: 1.5;
    background-color: $color__white;
    border: 1px solid $color__black;
    border-radius: 0;
    opacity: 0;

    @include responsive(lg) {
      @include sr-only();
    }

    option {
      width: 100%;
    }
  }

  .custom-select-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc-rem(45);
    height: 100%;
    border-left: 1px solid $color__black;
    pointer-events: none;
  }

  .custom-select-icon {
    width: 100%;
    height: 100%;
    transition: transform 0.3s $easing__out--expo;
  }

  .custom-select-opener {
    display: none;
    width: 100%;
    height: 55px;
    padding: calc-rem(16) calc-rem(24);
    color: $color__black;
    font-size: calc-rem(12);
    line-height: 1.5;
    letter-spacing: calc-rem(2);
    border: 1px solid $color__black;
    cursor: pointer;
    transition: border 0.3s ease-in;

    .has-error & {
      border-color: $color__red;
    }

    @include responsive(lg) {
      display: block;
    }
  }

  .custom-select-panel {
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 10;
    width: 100%;
    max-height: 200px;
    padding: calc-rem(16) 0;
    overflow: auto;
    background-color: $color__white;
    border: 1px solid $color__grey;
    transform: scaleY(0.5);
    transform-origin: 50% 0;
    opacity: 0;
    transition: opacity 0.2s ease, transform 0.3s ease;
    pointer-events: none;
  }

  .custom-select-option {
    padding: calc-rem(5) calc-rem(24);
    color: $color__grey;
    font-size: calc-rem(12);
    line-height: 2.5;
    letter-spacing: calc-rem(2);
    cursor: pointer;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &.has-focus {
      color: $color__black;
    }

    &.is-selected {
      color: $color__black;
      font-weight: 600;
    }
  }

  &.is-open {
    .custom-select-panel {
      transform: scaleY(1);
      opacity: 1;
      pointer-events: auto;
    }

    .custom-select-icon {
      transform: scaleY(-1);
    }
  }
}

// CHECKBOX
/* Customize the label (the container) */
.custom-checkbox,
.custom-checkbox-no-purple,
.custom-radio {
  cursor: pointer;
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    /* When the checkbox is checked, add a blue background */
    &:checked~.checkmark {
      background-image: linear-gradient(225deg, $color__pink, $color__orange);
      //border-color: transparent;

      /* Show the checkmark when checked */
      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: relative;
    flex-shrink: 0;
    height: 22px;
    width: 22px;
    background-color: $color__black;
    border: 1px solid $color__grey;
    border-radius: 2px;
    transition: background 0.2s;

    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 9px;
      height: 13px;
      border: solid $color__black;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  /* On mouse-over, add a grey background color */
  // &:hover input~.checkmark {
  //   background-color: $color__grey-dark;
  // }

  .text-label {
    line-height: 1.3;
  }

  &--group-actions {
    .checkmark {
      &:after {
        left: 4px;
        top: 8px;
        width: 12px;
        height: 0px;
        border-width: 4px 0 0 0;
        transform: none;
      }
    }

    input:checked~.checkmark {
      background-image: none;
      // background-color: $color__black;
      border-color: transparent !important;

      /* Show the checkmark when checked */
      &:after {
        display: block;
      }
    }
  }

  &--labeled {
    width: auto;
  }

  &--light {
    .text-label {
      color: $color__grey-darker;
    }

    .checkmark {
      background-color: transparent;
    }
  }
}


.custom-checkbox-no-purple,
.custom-radio-no-purple {
  input {
    &:checked~.checkmark {
      background-image: none !important;
    }
  }
}

.custom-radio {
  .checkmark {
    border-radius: 50%;

    &:after {
      transform: rotate(45deg) scale(0.8);
    }
  }

  &+.custom-radio {
    &:not(.radio-horizontal) {
      margin-top: calc-rem(16);
    }
  }
}

// STATUS RADIO FOR FEED CREATION, SETTINGS, etc
.custom-status {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  border: 1px solid $color__grey;
  border-radius: 5px;

  &__label,
  &__icon {
    z-index: 1;
    flex-shrink: 0;
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background: $color__black;
    border: 1px solid $color__grey;
    border-radius: 10px;

    &::before {
      content: '';

      width: 8px;
      height: 8px;
      background-color: $color__white;
      border-radius: 10px;
      transform: scale(0);
      opacity: 0;
      transition: 0.2s transform $easing__out--cubic, 0.2s opacity $easing__out--cubic;
    }

    .has-error & {
      border: solid 1px $color__red;
    }
  }

  &__label {
    margin-right: 57px;
    margin-left: 0;
    font-size: calc-rem(14);
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: $color__grey-dark;
    border-radius: 5px;
    opacity: 0;
    transition: 0.2s opacity $easing__out--cubic;
  }

  input[type='radio'] {
    @include sr-only;

    width: 100%;
    height: 100%;
  }

  input:checked+&__icon {
    background: linear-gradient(225deg, $color__pink 0%, $color__red-orange 100%);
    border-color: transparent;

    &::before {
      transform: scale(0.8);
      opacity: 1;
    }
  }

  input:checked+&__icon+&__bg {
    opacity: 1;
  }
}

// TEXTUAL INPUTS (text, email, phone, ...)
.text-input {
  width: 100%;
  height: calc-rem(48);
  padding: calc-rem(16) calc-rem(24);
  color: $color__white;
  font-size: calc-rem(15);
  font-family: $font-secondary;
  line-height: 1.56;
  letter-spacing: normal;
  background-color: transparent;
  border: 1px solid $color__grey;
  border-radius: 5px;
  transition: 0.3s border-color ease-in;

  &::placeholder {
    color: $color__grey-lighter;
  }

  .has-error & {
    border-color: $color__red;
  }
}

// TEXTAREA
.textarea {
  @extend .text-input;

  height: 150px;
  resize: none;
}

.select {
  @extend .text-input;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 9"><path fill="DimGray" d="m7 8.5c-.4 0-.8-.1-1.1-.4l-6-6 2.1-2.1 5 4.9 4.9-4.9 2.1 2.1-6 6c-.2.3-.6.4-1 .4z"/></svg>') 97% 48% no-repeat transparent;
  background-position: 90% center;
  background-size: 20px 10px;

  option {
    &[value=''] {
      color: $color__grey;
    }
  }
}

.ant-select:not(.ant-select-customize-input) {
  width: 100%;
  color: $color__white;
  font-size: calc-rem(15);
  font-family: $font-secondary;
  letter-spacing: normal;

  .ant-select-selector {
    height: calc-rem(48);
    padding: calc-rem(16) calc-rem(24);
    border: 1px solid $color__grey;
    border-radius: 5px;
    background-color: transparent;

    .ant-select-selection-item {
      line-height: 1.1;
    }
  }

  .ant-select-arrow {
    color: $color__white;
  }
}

.ant-white {
  .ant-select {
    .ant-select-arrow {
      color: $color__grey;
    }
  }
}

.ant-select-dropdown {
  background-color: $color__grey-ultralight;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $color__grey-ultralight;
}

.label {
  display: block;
  margin-top: calc-rem(24);
  margin-bottom: calc-rem(8);
  color: $color__grey-light;
  font-family: $font-primary;
  font-size: calc-rem(10);
  line-height: 1.6;
  letter-spacing: 1.67px;
  text-transform: uppercase;

  &--med {
    font-family: $font-secondary;
  }

  &--white {
    color: $color__white;
  }
}

.input-add {
  position: relative;

  .text-input {
    padding-right: calc-rem(60);
  }

  .bt-add-hashtag {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-70%, -50%);
  }
}

.input-text-grey {
  width: 100%;
  padding: 14px 16px 10px 16px;
  color: $color__grey-darker;
  font-size: calc-rem(15);
  font-family: $font-secondary;
  line-height: 1.6;
  background-color: $color__grey-megalight;
  border: none;
  border-radius: 5px;

  &::placeholder {
    color: $color__grey-lighter;
    font-family: $font-secondary;
  }
}

.NativeDatepicker {
  width: 100%;
  background: transparent url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMjAgMjAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDIwIDIwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+Cjx0aXRsZT42Rjc2RkU2Qy1CQkNDLTREREMtQTlFNC05NEE4OUZEQzFENDA8L3RpdGxlPgo8ZyBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY0MC4wMDAwMDAsIDM2NS4wMDAwMDApIj4KCTxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU2Ni4wMDAwMDAsIDE0LjAwMDAwMCkiPgoJCTxnIGlkPSJjYWxlbmRhciIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy4xMjUwMDAsIDMuMTI1MDAwKSI+CgkJCTxwYXRoIGlkPSJTaGFwZSIgY2xhc3M9InN0MCIgZD0iTS0xMTk0LTM3Ny45aC0xLjJ2LTAuNmMwLTAuMi0wLjItMC40LTAuNC0wLjRzLTAuNCwwLjItMC40LDAuNHYwLjZoLTZ2LTAuNgoJCQkJYzAtMC4yLTAuMi0wLjQtMC40LTAuNHMtMC40LDAuMi0wLjQsMC40djAuNmgtMS4yYy0xLDAtMS44LDAuOC0xLjgsMS44djkuMWMwLDEsMC44LDEuOCwxLjgsMS44aDEwLjJjMSwwLDEuOC0wLjgsMS44LTEuOHYtOS4xCgkJCQlDLTExOTIuMi0zNzcuMS0xMTkzLTM3Ny45LTExOTQtMzc3Ljl6IE0tMTE5My4xLTM2N2MwLDAuNS0wLjQsMC45LTAuOSwwLjloLTEwLjJjLTAuNSwwLTAuOS0wLjQtMC45LTAuOXYtNy4xCgkJCQljMC0wLjEsMC4xLTAuMSwwLjEtMC4xaDExLjhjMC4xLDAsMC4xLDAuMSwwLjEsMC4xVi0zNjd6Ii8+CgkJPC9nPgoJPC9nPgo8L2c+Cjwvc3ZnPgo=') no-repeat 98% center;
  background-size: 24px;
}

.file-input {
  &::file-selector-button {
    margin-right: 8px;
    border: 1px solid $color__grey;
    padding: 12px 12px 8px;
    color: #fff;
    line-height: 1;
    background: transparent;
    cursor: pointer;
    border-radius: 5px;
    transition: .4s ease-in-out background-color;

    &:hover {
      background-color: $color__grey;
    }
  }
}

// collection item in landing
.collection-item {
  margin-top: 1rem;
  border: 1px solid $color__grey;
  border-radius: 5px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    text-align: left;
    padding: 0.75rem 1rem .5rem;
    margin: 0;
    border-bottom: 1px solid transparent;
    width: 100%;
    cursor: pointer;

    &.is-open {
      border-color: $color__grey;

      svg {
        transform: scaleY(-1);
      }
    }

    svg {
      width: 1rem;
    }
  }

  &__content {
    overflow: hidden;

    &-wrap {
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows .4s ease-in-out;

      &.is-open {
        grid-template-rows: 1fr;
      }
    }
  }

  &__inner {
    padding: 0 1rem 1rem;
  }
}

.collection-wrap {
  padding-left: 0;
  list-style-type: none;
}

// number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}