.filters {
  margin: 2rem 2.5rem 1rem 2.5rem;
  border-bottom: solid 1px $color__grey-mid;

  .label {
    margin-top: 0;
  }

  &__first-row {
    gap: 1rem;
  }

  &__second-row {
    justify-content: space-between;

    .flex {
      gap: 4rem;
    }
  }

  &__search {
    flex: 2;
  }
}

.top-list {
  padding: 0rem 2.5rem .5rem 2.5rem;
}