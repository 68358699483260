.notices {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 201;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: calc-rem(24);
  transition: opacity 0.5s $easing__out--cubic;
  pointer-events: none;
}

.notice {
  position: relative;
  width: 100%;
  max-width: 360px;
  margin-top: calc-rem(24);
  padding: 17px 45px 16px 20px;
  background: $color__white;
  border-radius: 45px;
  pointer-events: auto;
  animation: openNotice .3s $easing__out--sine;
  animation-fill-mode: forwards;

  &__inner {
    position: relative;
    /* max-width: 90vw;
    max-height: 90%; */
    color: $color__black;
    background-color: $color__white;
  }

  &__close-button {
    position: absolute;
    top: 50%;
    right: calc-rem(20);
    width: 20px;
    height: 20px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    transform: translateY(-50%);
    transition: opacity 0.3s $easing__out--cubic;

    &:hover {
      opacity: 0.6;
    }

    .icon {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    color: $color__grey-darker;
    font-size: calc-rem(24);
    line-height: 1;
  }

  &__msg {
    color: $color__grey-darker;
    font-size: calc-rem(13);
    line-height: 1.54;
  }

  &__visual {
    position: relative;
    flex-shrink: 0;
    width: 40px;
    height: auto; // 40px;
    min-height: 40px;
    margin-right: 16px;;    
    border-radius: 6px;
    overflow: hidden;

    video {
      @extend .abs-center;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }

  &-download {
    display: flex;
    align-items: center;

    &.downloaded {
      .download-bar {
        display: none;
      }

      .notice__msg {
        display: block;
      }
    }

    .content {
      flex-grow: 1;
    }

    .download-bar {
      position: relative;
      width: 100%;
      height: 4px;
      margin: 8px 0;

      &__track,
      &__progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }

      &__track {
        width: 100%;
        background-color: $color__grey-ultralight;
      }

      &__progress {
        width: 0%;
        background: linear-gradient(to right, $color__orange, $color__pink);
        background-size: 100%;
        transition: width 1s ease-out;
      }
    }

    .notice__msg {
      display: none;
    }
  }

  &-collection-created,
  &-feed-created,
  &-feed-deleted,
  &-message-created,
  &-message-deleted,
  &-profile-updated {
    display: flex;
    align-items: center;

    .icon {
      margin-right: calc-rem(16);
    }

    .notice__msg {
      margin-top: calc-rem(8);
    }
  }

  &-feed-created,
  &-message-created,
  &-feed-deleted,
  &-message-deleted,
  &-profile-updated {
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 22px;
      height: 22px;
      margin-right: 0;
      transform: translate(-50%,-50%);

      &-wrapper {
        position: relative;
        width: 44px;
        height: 44px;
        flex-shrink: 0;
        margin-right: calc-rem(16);
        z-index: 1;
        background: linear-gradient(225deg,$color__pink,$color__orange);
        background-clip: padding-box;
        border-radius: 50%;
      }

      &-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: $color__white;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-rejected-from-feed,
  &-added-to-collection {
    display: flex;
    align-items: center;

    .notice__msg {
      margin-top: calc-rem(8);
    }
  }
}