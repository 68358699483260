.page-collections {
  .page__title {
    margin-top: 0;
  }

  .sticky-wrapper {
    // top: 158px;
    padding-left: 0;
    padding-right: 0;
  }
}

.collections-list {
  li {
    width: calc(25% - 30px);
    margin-right: calc-rem(40);
    flex-shrink: 0;
    margin-top: calc-rem(40);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  &__item {
    display: block;
    width: 100%;

    &.is-disabled {
      opacity: 0.6;
      cursor: not-allowed;

      >* {
        pointer-events: none;
      }
    }

    &.is-loading {
      .mosaic .lds-dual-ring {
        display: block;
      }
    }

    &__title {
      margin-top: calc-rem(16);
      color: $color__white;
      font-size: calc-rem(24);
      line-height: 1;
      text-align: center;
    }

    &__count {
      margin-top: calc-rem(8);
      color: $color__white;
      font-size: calc-rem(11);
      line-height: 1.45;
      letter-spacing: 2px;
      text-align: center;
      text-transform: uppercase;
    }

    .mosaic {
      position: relative;
      width: 100%;
      background-color: $color__grey-darker;
      border-radius: 10px;
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 100%;
      }

      &:hover {
        .overlay {
          opacity: 1;
        }
      }

      &-thumb {
        position: absolute;
        width: 50%;
        height: 50%;
        background-size: cover;
        top: 0;

        &:before {
          content: '';
          width: 100%;
          display: block;
          padding-top: 100%;
        }

        &:nth-child(1) {
          border-right: 1px solid $color__black;
          border-bottom: 1px solid $color__black;
        }

        &:nth-child(2) {
          right: 0;
          border-bottom: 1px solid $color__black;
        }

        &:nth-child(3) {
          top: 50%;
          border-right: 1px solid $color__black;
        }

        &:nth-child(4) {
          top: 50%;
          right: 0;
        }
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color__black, 0.5);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 5px;

        .bt-download,
        .bt-delete,
        .bt-share {
          pointer-events: initial;
        }
      }

      .lds-dual-ring {
        @extend .abs-center;
        display: none;
      }
    }

    &--light {
      .mosaic {
        background-color: $color__grey-ultralight;
        transition: box-shadow 0.3s;

        &-thumb {
          border-color: $color__white !important;
        }
      }

      .collections-list__item__title {
        color: $color__grey-darker;
      }

      .collections-list__item__count {
        color: $color__grey-darker;
      }

      &:hover:not(.is-disabled) {
        .mosaic {
          box-shadow: 0 0 10px 5px rgba($color__black, 0.2);
        }
      }
    }
  }
}

.collection-medias-list {
  >div {
    width: calc(25% - 40px);
    flex-shrink: 0;
    margin-right: calc-rem(40);
    margin-top: calc-rem(40);
  }

  &__item {
    position: relative;

    border-radius: 10px;
    overflow: hidden;

    &:nth-child(4n) {
      margin-right: 0;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &:hover,
    &.is-active {
      .overlay {
        opacity: 1;
      }
    }

    .visual,
    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    video {
      @extend .abs-center;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .visual {
      background-size: cover;
      background-position: center;
      background-color: $color__grey-ultralight;
    }

    .overlay {
      background-color: rgba($color__grey-darker, .5);
      opacity: 0;
      transition: opacity .3s;

      .custom-checkbox,
      .custom-checkbox-no-purple {
        position: absolute;
        top: calc-rem(16);
        left: calc-rem(16);

        input:not(:checked)+.checkmark {
          border-color: $color__grey-megalight;
        }
      }
    }
  }
}