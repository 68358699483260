.alert {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding: calc-rem(7);
    color: $color__grey-dark;
    font-size: calc-rem(15);
    font-family: $font-secondary;
    line-height: 1.56;
    background-color: $color__grey-light;
    border: 1px solid $color__grey;
    border-radius: 5px;

    &-danger {
        color: $color__red;
        border-color: $color__red;
        background-color: rgba($color__red, .2);
    }
}