.ask-form {
  max-width: 576px;
  min-height: 100vh;
  margin: 0 auto;
  padding: calc-rem(40) calc-rem(40) calc-rem(16) calc-rem(40);
  background: $color__grey-megalight;

  .icon-diapo {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
  }

  .logo {
    max-width: 50%;
    border-radius: 50%;
  }

  .text-gradient {
    margin-top: calc-rem(48);
    font-size: calc-rem(32);
  }

  .gen-text {
    color: $color__grey-darker;
    font-size: calc-rem(15);
    line-height: 1.6;
    white-space: break-spaces;
  }

  .visual {
    position: relative;
    border-radius: 10px;
    background-color: $color__grey-ultralight;
    background-size: cover;
    background-position: center;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    video {
      @extend .abs-center;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .second-title {
    color: $color__grey-darker;
    font-size: calc-rem(32);
    line-height: normal;
  }

  .footer-nav {
    margin-top: calc-rem(60);

    a {
      margin: 0 12px;
      color: $color__grey-lighter;
      font-family: $font-secondary;
      font-size: calc-rem(11);
      line-height: 1.45;
      letter-spacing: 2px;
      text-transform: uppercase;
      transition: color .2s;

      &:hover {
        color: $color__grey-ultralight;
      }
    }
  }
}