.modals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transition: opacity 1s $easing__out--cubic;
  // pointer-events: none;
}

.modal {
  position: absolute;
  padding: calc-rem(40);
  background: $color__white;
  border-radius: 10px;
  animation: openModal 0.3s $easing__out--sine;
  animation-fill-mode: forwards;

  // pointer-events: auto;

  &__inner {
    position: relative;
    /* max-width: 90vw;
    max-height: 90%; */
    color: $color__black;
    background-color: $color__white;
    // height: calc(100vh - 5rem);
    overflow: auto;
  }

  &__close-button {
    position: absolute;
    top: calc-rem(25);
    right: calc-rem(25);
    width: 20px;
    height: 20px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s $easing__out--cubic;

    &:hover {
      opacity: 0.6;
    }

    .icon {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    color: $color__grey-darker;
    font-size: calc-rem(32);
    line-height: 1;
  }

  &__grip {
    margin-top: calc-rem(16);
    color: $color__grey-light;
    font-size: calc-rem(15);
    line-height: 1.6;
  }

  &__description {
    margin-top: calc-rem(16);
    color: $color__grey-darker;
    font-size: calc-rem(15);
    line-height: 1.6;

    a {
      color: $color__black;
      text-decoration: underline;
    }

    strong {
      font-family: $font-secondary;
      font-weight: normal;
    }
  }

  .visual {
    position: relative;
    flex-shrink: 0;

    video {
      @extend .abs-center;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .text-input {
    color: $color__grey-darker;
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }

  &-media-details {
    display: flex;

    max-width: 774px;

    .visual {
      width: 347px;
      height: 347px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: calc-rem(16);
      border-radius: 10px;
      background-color: $color__grey-megalight;
      background-size: cover;
      background-position: center;

      .ctas {
        z-index: 1;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 0 0 calc-rem(40);
    }

    .user-name {
      font-family: $font-secondary;
      margin-right: calc-rem(16);
    }

    .modal__description {
      width: 90%;
      margin-top: 0;
      font-size: calc-rem(15);
      word-break: break-word;
      @include ellipsis(1.6em, 3, $color__grey-dark);
    }

    .collections-info {
      color: $color__grey-light;
      margin-top: calc-rem(33);

      &-title {
        font-family: $font-primary;
        font-size: calc-rem(15);
      }

      ul {
        li {
          font-family: $font-secondary;
          font-size: calc-rem(13);
          line-height: 1.6;
        }
      }
    }
  }

  &-create-collection {
    .bt-back {
      margin-right: calc-rem(16);
      cursor: pointer;
    }
  }

  &-add-to-collection {
    ul {
      width: 834px;
      max-height: 50vh;
      margin: 0 -15px;
      padding: 0 15px;
      display: flex;
      flex-wrap: wrap;
      overflow: auto;

      li {
        width: calc(25% - 30px);
        margin-right: calc-rem(38);
        flex-shrink: 0;
        margin-top: calc-rem(38);

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      .collections-list__item {
        .overlay {
          display: none;
        }
      }
    }

    .collections-list__item__title {
      margin-top: 8px;
      font-size: calc-rem(18);
      line-height: 1.56;
    }

    .collections-list__item__count {
      margin-top: 0;
      font-size: calc-rem(11);
      line-height: 1.45;
    }

    .collections-list__item_create {
      position: relative;
      width: 100%;
      border-radius: 10px;
      background-color: $color__grey-ultralight;
      cursor: pointer;
      transition: box-shadow 0.3s;

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      .dotted-border {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        border: 1px dashed $color__grey-lighter;
        border-radius: 10px;
        transform: translate(-50%, -50%);
        transition: border 0.3s;
      }

      .picto-wrapper {
        position: relative;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);

        .text-gradient {
          margin-top: 8px;
          font-size: 11px;
          font-family: $font-secondary;
          line-height: 1.45;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }

      .picto {
        z-index: 1;
        position: relative;
        width: 32px;
        height: 32px;
        margin: 0 auto;
        background: linear-gradient(225deg, $color__pink, $color__orange);
        background-clip: padding-box;
        border-radius: 50%;

        &__content {
          position: absolute;
          top: 50%;
          left: 50%;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          background-color: $color__grey-ultralight;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:hover {
        box-shadow: 0 0 10px 0 rgba($color__black, 0.15);
        /* .dotted-border {
          border: 2px dashed $color__grey-lighter;
        } */
      }
    }
  }

  &-delete-from-feed,
  &-delete-message {
    width: 700px;

    .flex {
      max-width: 404px;
      margin: calc-rem(24) auto 0 auto;

      button {
        width: 100%;
        max-width: 190px;
      }
    }
  }

  &-create-message,
  &-manual-add-feed-asset {
    width: 630px;

    .flex {
      margin: calc-rem(24) auto 0 auto;

      textarea {
        width: 100%;
        height: 230px;
        resize: none;
        padding: calc-rem(10);
        border: solid 1px $color__grey;
        border-radius: 5px;

        &::selection {
          text-shadow: none;
          background: $color__grey-ultralight;
        }

        &:focus {
          outline: none !important;
          border: 1px solid $color__grey;
        }
      }

      button {
        width: 100%;
        max-width: 165px;
      }
    }

    code {
      cursor: pointer;

      @extend .button, .button--border, .button--border--red;
      margin: calc-rem(8) calc-rem(8) 0 0;
    }
  }

  &-ask-message {
    display: flex;
    width: 100%;
    max-width: 840px;

    .col-left {
      position: relative;
      width: 41%;
      max-width: 345px;
      flex-shrink: 0;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: calc(100% + 80px);
        height: calc(100% + 80px);
        transform: translate(-40px, -40px);
        background-color: $color__grey-megalight;
      }

      .modal__description {
        position: relative;
        z-index: 1;
        max-height: 72px;
        // margin-bottom : 35px;
        overflow: auto;
        word-break: break-word;
      }

      .media-hashtags {
        span {
          opacity: 0.9999999;
        }
      }

      .media-stats {
        position: relative;
        z-index: 1;
      }
    }

    .visual {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: calc-rem(16);
      border-radius: 10px;
      background-color: $color__grey-ultralight;
      background-size: cover;
      background-position: center;

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      .custom-checkbox,
      .custom-checkbox-no-purple {
        position: absolute;
        top: 19px;
        left: 19px;
      }
    }

    .selection-info {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      padding: 8px 8px 6px 8px;
      color: $color__grey-darker;
      font-size: calc-rem(14);
      line-height: normal;
      background-color: rgba($color__white, 0.8);
      border-radius: 5px;
      transform: translateY(-100%);

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 25px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid rgba($color__white, 0.8);
        transform: translateY(100%);
      }
    }

    .icon-diapo {
      position: absolute;
      right: 16px;
      bottom: 16px;
      z-index: 2;
    }

    &__content {
      width: 59%;
      max-width: 495px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: calc-rem(16) 0 0 calc-rem(80);
    }

    .user-name {
      font-family: $font-secondary;
      margin-right: calc-rem(16);
    }

    .url-input {
      width: 100%;
      padding: calc-rem(16) calc-rem(16) calc-rem(12) calc-rem(16);
      color: $color__white;
      font-size: calc-rem(13);
      font-family: $font-secondary;
      line-height: 1.54;
      letter-spacing: normal;
      background-color: $color__black;
      border-radius: 0 0 5px 5px;
    }
  }
}

.tabs {
  &-item {
    padding: calc-rem(14) calc-rem(16) calc-rem(10) calc-rem(16);
    color: $color__grey-lighter;
    font-size: calc-rem(11);
    font-family: $font-secondary;
    line-height: 1.33;
    letter-spacing: normal;
    text-transform: uppercase;
    border-radius: 15px 15px 0 0;
    border: 1px solid rgba($color__grey-light, 0.1);
    border-bottom: 0;
    transition: background 0.2s, border 0.2s;
    cursor: pointer;

    &.is-active {
      background-color: $color__grey-megalight;
      border-color: $color__grey-megalight;
      span {
        @extend .text-gradient, .text-gradient--H;
      }
    }
  }

  &-contents {
    &-item {
      display: none;
      padding: calc-rem(16);
      background: $color__grey-megalight;

      .scrollable {
        //  max-height: 120px - 32;
        // overflow: auto;
        color: $color__grey-darker;
        font-size: calc-rem(13);
        font-family: $font-primary;
        line-height: 1.54;
        letter-spacing: normal;
        white-space: break-spaces;
        word-break: break-word;
      }

      &.is-active {
        display: block;
      }
    }
  }
}
