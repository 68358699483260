.page-account {
  &-profile {
    .profile-picture {
      .visual {
        width: calc-rem(80);
        height: calc-rem(80);
        flex-shrink: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: $color__white;
        border-radius: 50%;
        position: relative;
      }
    }

    .profile-picture-icon {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: calc-rem(30);
      height: calc-rem(30);
      flex-shrink: 0;
      background: $color__black;
      border: 1px solid $color__grey;
      border-radius: 20px;
      transition: all 0.2s;
      cursor: pointer;

      .icon {
        @extend .abs-center;
        width: calc-rem(18);
        height: calc-rem(18);
        fill: $color__white;
        opacity: 1;
        transition: opacity 0.2s;

        &-color {
          opacity: 0;
        }
      }

      &.is-disabled {
        opacity: 0.3;
        pointer-events: none;
      }

      &:not(.is-disabled):hover {
        background: $color__white;
        border-color: $color__white;

        .icon {
          opacity: 0;

          &-color {
            opacity: 1;
          }
        }
      }
    }

    .profile-name {
      margin-left: calc-rem(25);
    }
  }
  &-plugins {
    div.input-copy-paste {
      margin-top: 0;
      button.button--plain {
        padding-top: calc-rem(2);
        padding-bottom: 0;
        padding-left: calc-rem(10);
        padding-right: calc-rem(10);
        border-top-left-radius: 0%;
        border-bottom-left-radius: 0%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      input {
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;
      }
    }
  }

  .profile-picture-icon {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc-rem(30);
    height: calc-rem(30);
    flex-shrink: 0;
    background: $color__black;
    border: 1px solid $color__grey;
    border-radius: 20px;
    transition: all 0.2s;
    cursor: pointer;

    .icon {
      @extend .abs-center;
      width: calc-rem(18);
      height: calc-rem(18);
      fill: $color__white;
      opacity: 1;
      transition: opacity 0.2s;

      &-color {
        opacity: 0;
      }
    }

    &.is-disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &:not(.is-disabled):hover {
      background: $color__white;
      border-color: $color__white;

      .icon {
        opacity: 0;

        &-color {
          opacity: 1;
        }
      }
    }
  }

  .profile-name {
    margin-left: calc-rem(25);
  }
}

.profile-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: calc-rem(8);
  padding-left: calc-rem(30);
  padding-right: calc-rem(30);
  height: calc-rem(60);
  color: $color__white;
  font-size: calc-rem(15);
  background-color: $color__grey-dark;
  border-radius: 10px;
  transition: background 0.2s;

  > .profile-list__item__ctas {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: calc-rem(100);
  }

  &:hover {
    color: $color__white;
    background-color: $color__grey-mid;
  }

  > span {
    display: inline-block;
    line-height: normal;

    img {
      border-radius: 50%;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.profile-facebook-btn {
  @extend .button;
  @extend .button--plain;
  @extend .button--plain--red;
}
