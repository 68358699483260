.sidebar {
    flex-shrink: 0;
    width: calc-rem(480);
    height: 100%;
    background-color: $color__grey-dark;

    &__content {
        position: fixed;
        top: calc-rem(158);
        width: calc-rem(480);
        padding: calc-rem(32) calc-rem(40);
        
        height: calc(100vh - 9.875rem);
        overflow: auto;

        > div:not(:first-child) {
            margin-top:calc-rem(32);
        }

        &.is-loading {
            pointer-events: none;
        }
    }

    &__title {
        margin-bottom: -8px;
        color: $color__grey-light;
        font-size: calc-rem(32);
        line-height: 1;
    }
}

.with-sidebar {
    display: flex;
    width: 100%;
    height: 100%;
}