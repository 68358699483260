.medias-list {
  max-width: 100%;
  opacity: 1;
  transition: opacity 0.2s;

  &.is-loading {
    opacity: 0.3;
    pointer-events: none;
  }

  &.grid {
    display: flex;
    flex-wrap: wrap;
    gap: calc-rem(12);
    
    > li {
      align-items: stretch;
      width: calc(100% / 6 - 10px);
      margin-bottom: calc-rem(10);

      &.feed__unit--center {
        width: 100%;
      }
    }
  }
}

.feed-medias-list__item {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: calc-rem(8);
  padding: calc-rem(24) calc-rem(12);
  background-color: $color__grey-dark;
  border-radius: 10px;

  > div {
    margin: 0 calc-rem(12);
  }

  .action_cb {
    align-self: center;
    flex-shrink: 0;
  }

  .thumb {
    position: relative;
    width: calc-rem(160);
    height: calc-rem(160);
    align-self: center;
    flex-shrink: 0;
    border-radius: 10px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    video {
      @extend .abs-center;
      width: 100%;
      min-width: 100%;
      height: auto;
      min-height: 100%;
    }

    .icon {
      position: absolute;
      right: 8px;
      bottom: 8px;
      width: 24px;
      height: 24px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 1;
    flex-grow: 1;
    width: 45%;

    &--top {
      font-size: calc-rem(15);
      word-break: break-word;
      // line-height: 1.6;
      @include ellipsis(1.4em, 5, $color__grey-dark);

      .media-username {
        display: inline-block;
        margin-right: calc-rem(16);
        font-family: $font-secondary;
      }
    }
  }

  .ctas {
    width: calc-rem(215);
    flex-shrink: 0;

    .button {
      width: 100%;

      &:not(:first-child) {
        margin-top: calc-rem(20);
      }
    }
  }
}

.select-checkbox {
  width: 22px;
} 

.media-hashtags,
.media-stats {
  display: block;
  // justify-content: space-between;
  color: $color__grey-light;
  font-size: calc-rem(13);
  line-height: 1;
}

.media-stats {
  display: flex;
  // justify-content: space-between;
  color: $color__grey-light;
  font-size: calc-rem(13);
  line-height: 1;
}

.media-hashtags {
  // justify-content: space-between;

  span {
    font-family: $font-secondary;
    margin-right: calc-rem(24);
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    /* &:not(:first-child) {
            margin-left: calc-rem(24);
        } */
  }
}

.media-hashtags.flex-wrap {
  flex-wrap: wrap;
}

.media-stats {
  justify-content: space-between;
  margin-top: calc-rem(8);
  > div {
    display: flex;
    align-items: center;

    /*  &:not(:first-child) {
            margin-left: calc-rem(32);
        } */

    .icon {
      width: 15px;
      flex-shrink: 0;
      margin-right: calc-rem(8);
    }
  }
}
