.select-module {
  position: relative;
  flex: 1;

  select {
    margin-top: calc-rem(8);
    @extend .text-input;
    padding: 1rem;
  }

  &.with-icon {
    select {
      padding-left: calc-rem(32);
    }

    .icon {
      position: absolute;
      bottom: 0;
      left: 10px;
      transform: translateY(-100%);
    }
  }
}