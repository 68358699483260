.search-module {
  .inputs-wrapper {
    margin: 8px 0 0;
    padding: 12px 16px 11px;
    background-color: $color__black;
    border-radius: 24px;
    border: solid 1px $color__grey;

    input[type="text"] {
      flex-grow: 1;
      color: $color__grey-ultralight;
      font-family: $font-secondary;
      font-size: calc-rem(15);
      background-color: transparent;
      border: none;

      &::placeholder {
        color: $color__grey;
      }
    }

    .search-trigger {
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}