$color__white: #fff;
$color__black: #000;
$color__grey-darker: #0d0d0d;
$color__grey-dark: #151515;
$color__grey-mid: #262626;
$color__grey: #4b4b4b;
$color__grey-light: #7b7b7b;
$color__grey-lighter: #9c9c9c;
$color__grey-ultralight: #e5e5e5;
$color__grey-megalight: #f3f3f3;

// $color__white: #000;
// $color__black: #fff;
// $color__grey-darker: #f3f3f3;
// $color__grey-dark: #e5e5e5;
// $color__grey-mid: #9c9c9c;
// $color__grey: #7b7b7b;
// $color__grey-light: #4b4b4b;
// $color__grey-lighter: #262626;
// $color__grey-ultralight: #151515;
// $color__grey-megalight: #0d0d0d;

$color__red: #E110DB; // v2 #ff00d0; // v1 #ff364b;
$color__green: #43de1d;
$color__blue: #2C66C6;
$color__orange:#E110DB; // v2 #7f46ff;// v1 #ff5600;
$color__orange-light: #ffaf19;
$color__pink: #E110DB; // v2 #ff00d0; // v1 #ff00ea; //#ff00d0;
$color__red-orange: #E110DB; // v2 #7f46ff;// v1 #ff4600;

$colors: ( //used to generate icons classes for instance
'white': $color__white,
'black': $color__black,
'grey-darker': $color__grey-darker,
'grey-dark': $color__grey-dark,
'grey-mid': $color__grey-mid,
'grey': $color__grey,
'grey-lighter': $color__grey-lighter,
'grey-light': $color__grey-light,
'red': $color__red,
'red-orange': $color__red-orange,
'orange': $color__orange,
'pink': $color__pink,
'blue': $color__blue,
)