.list-displayer {
  display: flex;
  align-items: center;
  margin-left: calc-rem(24);

  &__label {
    color: $color__grey-light;
    font-family: $font-secondary;
    font-size: calc-rem(10);
    letter-spacing: 1.67px;
    text-transform: uppercase;
  }

  .icon {
    margin: 0 calc-rem(8) 0 calc-rem(16);
    cursor: pointer;

    &:last-child {
      margin: 0;
    }
  }
}