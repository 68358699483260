.button {
  display: inline-block;
  padding: calc-rem(13) calc-rem(24) calc-rem(11) calc-rem(24);
  font-size: calc-rem(10);
  font-family: $font-secondary;
  line-height: 1.45;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-width: 0;
  border-radius: calc-rem(12);
  cursor: pointer;

  @include responsive(md) {
    padding: calc-rem(13) calc-rem(30) calc-rem(11) calc-rem(30);
    font-size: calc-rem(11);
    line-height: 1.45;
    letter-spacing: 2px;
  }

  @each $name, $color in $colors {
    &#{&}--#{$name} {
      background-color: $color;
    }
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: wait;
  }

  &.is-loading {
    cursor: wait;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc-rem(12) calc-rem(14) calc-rem(8) calc-rem(14);

    .icon {
      flex-shrink: 0;
      margin-right: calc-rem(8);
      transition: fill 0.3s;
    }
  }

  &--plain {
    transition: background-color 0.3s, color 0.3s;

    @each $name, $color in $colors {
      &#{&}--#{$name} {
        color: $color__white;
        background-color: $color;

        &:not(.is-disabled):hover {
          background-color: darken($color, 5%);
        }
      }
    }

    // &.is-disabled {
    //   opacity: 1;
    //   @extend .button--plain--grey;
    // }

    &--gradient {
      color: $color__white;
      background: linear-gradient(225deg, $color__pink 0%, $color__orange 100%);
    }
  }

  &--border {
    // transition: all .3s ease-out;
    background-color: transparent;
    // box-shadow: inset -50px 8px 20px 10px transparent;
    border-width: 1px;
    border-style: solid;
    transition: /* box-shadow .6s, */ background-color 0.3s, color 0.3s;

    .icon {
      max-width: calc-rem(20);
      max-height: calc-rem(20);
    }

    &--red {
      color: $color__red;
      border-color: $color__red;

      &:hover {
        color: $color__white;
        background-color: $color__red;
      }
    }

    &--white {
      color: $color__white;
      border-color: $color__white;

      .icon {
        fill: $color__white;
      }

      &:hover {
        color: $color__white;
        background-color: $color__grey;
      }
    }

    &--grey {
      color: $color__white;
      border-color: $color__grey;

      .icon {
        fill: $color__white;
      }

      &:hover {
        color: $color__white;
        background-color: $color__grey;
      }

      &.bg-light {
        color: $color__grey;

        .icon {
          fill: $color__grey;
        }

        &:hover {
          color: $color__white;
          background-color: $color__grey;

          .icon {
            fill: $color__white;
          }
        }
      }
    }

    &--grey-lighter {
      color: $color__grey-darker;
      border-color: $color__grey-lighter;

      .icon {
        fill: $color__grey-darker;
      }

      &:hover {
        color: $color__white;
        background-color: $color__grey-lighter;
        .icon {
          fill: $color__white;
        }
      }
    }

    /* &:hover {
      color: $color__white;

      // background-color: $color__orange;
      // box-shadow: inset -50px 8px 20px 10px #ff00d0;
      background-color: $color__red;
    } */
  }

  &-arrow {
    padding: calc-rem(24) calc-rem(24) calc-rem(20);
    border: 1px solid $color__grey-lighter;
    border-radius: 33px;

    .icon {
      display: inline-block;
      // width: calc-rem(6);
      // height: calc-rem(12);
      margin-left: calc-rem(16);
      transition: transform 0.2s ease-out;
    }

    &:hover {
      .icon {
        transform: translateX(5px);
      }
    }
  }

  &-back {
    padding: 0;
    font-size: calc-rem(12);
    font-family: $font-secondary;
    line-height: 1.6;
    letter-spacing: 2.5px;
    text-transform: uppercase;

    .icon {
      display: inline-block;
      // width: calc-rem(6);
      // height: calc-rem(12);
      margin-top: -2px;
      margin-right: calc-rem(16);
      transform: rotate(180deg);
      transition: transform 0.2s ease-out;
    }

    &:hover {
      .icon {
        transform: rotate(180deg) translateX(5px);
      }
    }
  }

  &__spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);

      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 3px solid $color__white;
      border-color: $color__white transparent $color__white transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.bt-add-hashtag {
  width: calc-rem(24);
  height: calc-rem(24);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color__white;
  border-radius: 50%;
  cursor: pointer;
}

.bt-download,
.bt-share,
.bt-delete,
.bt-tag {
  width: calc-rem(40);
  height: calc-rem(40);
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color__white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}


.button-more {
  display: flex;
  align-items: center;
  background: transparent;
  white-space: nowrap;
  border: none;
  padding: 0;
  text-transform: uppercase;
  font-size: calc-rem(10);
  font-family: $font-secondary;
  letter-spacing: 1.67px;
  cursor: pointer;
  transition: .4s ease-in-out color;

  &:hover {
    color: $color__pink;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height:1.25rem;
    flex-shrink: 0;
    margin-right: .5rem;
    background-color: $color__pink;
    border-radius: 50%;
  }

  svg {
    width: .75rem;
    fill: $color__white;
  }
}