.section {
  position: relative;
  padding: $headerHmobile 0;
  transition: opacity 1s;

  @include responsive(md) {
    padding: calc-rem(110) 0;
  }

  &:last-child {
    padding-bottom: calc-rem(220);
  }

  &.invisible {
    opacity: 0;
  }

  .shapes {
    position: absolute;
    top: 0;
    left: 0;

    svg {
      width: 100%;
    }
  }
}