.hashtags-list {
  
  .hashtag-list {
    flex-wrap: wrap;

    &__item {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      margin-top: calc-rem(16);
      margin-right: calc-rem(16);
      padding: 5px 8px 3px;
      background-color: $color__white;
      border-radius: 8px;
  
      &-label {
          @extend .text-gradient;
          font-size: calc-rem(13);
          font-family: $font-secondary;
      }
  
      .bt-remove {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc-rem(16);
          height: calc-rem(16);
          margin-left: calc-rem(8);
          background-image: linear-gradient(225deg, $color__pink, $color__orange);
          border-radius: 50%;
          border-radius: 50%;
          cursor: pointer;
  
          .icon {
              fill: $color__white;
          }
      }
    }
  }

  &.dark {
    .hashtag-list__item-label {
      color: $color__grey-darker;
      -webkit-text-fill-color: $color__grey-darker;
      background: none;
    }

    .bt-remove {
      background-color: $color__black;
      background-image: none;
    }
  }
}