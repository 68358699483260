@use 'sass:math';

.page-feeds {
  .search-module {
    min-width: calc-rem(385);
  }

  &-creation,
  &-settings {
    .counter {
      margin-top: calc-rem(8);
      font-size: calc-rem(13);
      line-height: 1.54;

      span {
        color: $color__grey-lighter;
      }

      &.red {
        color: red;
      }
    }
  }
}

.feeds-list__item,
.page-feeds .sort-bar {
  > * {
    width: percentage(math.div(1, 9));
    flex-shrink: 0;

    &:last-child {
      display: flex;
      justify-content: flex-end;
    }

    &:nth-child(4) {
      // width: percentage(math.div(2, 9));
      // width: calc(#{percentage(math.div(2, 9))} - 10px);
    }

    &:first-child {
      width: percentage(math.div(1, 9));
    }
  }
}

.feeds-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: calc-rem(8);
    padding-left: calc-rem(30);
    padding-right: calc-rem(30);
    color: $color__white;
    font-size: calc-rem(15);
    background-color: $color__grey-dark;
    border-radius: 10px;
    transition: background 0.2s;

    &__source {
      img {
        width: 30px;
        height: 30px;
      }
    }

    &-link {
      color: $color__white;
      display: block;
      padding-top: calc-rem(30);
      padding-bottom: calc-rem(30);

      &:hover {
        color: $color__white;
      }
    }

    &:hover {
      background-color: $color__grey-mid;
    }

    > span {
      display: inline-block;
      line-height: normal;

      // &:first-child,
      // &:last-child {
      //   width: percentage(math.div(3, 11));
      // }
    }
  }
}

.status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &-indic {
    width: 12px;
    height: 12px;
    margin-right: calc-rem(8);
    border-radius: 50%;

    &--0 {
      background-color: $color__orange-light;
    }

    &--1 {
      background-color: $color__green;
    }

    &---1 {
      background-color: red;
    }
  }
}

.status-radio {
  margin-right: calc-rem(24);
}

.grouped-actions {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc-rem(20);
  background-color: $color__white;
  transform: translateY(100%);
  transition: transform 0.2s ease-out;

  &.is-active {
    transform: translateY(0);
  }

  &__counter {
    color: $color__grey-darker;
    font-size: calc-rem(24);
  }

  &__ctas {
    display: flex;
    align-items: center;

    button:not(:first-child) {
      margin-left: calc-rem(16);
    }
  }
}

.asset-card {
  position: relative;
  width: 100%;
  height: 100%;
  padding: calc-rem(12);
  color: $color__white;
  font-size: calc-rem(15);
  background-color: $color__grey-dark;
  border-radius: 10px;
  transition: background 0.2s;

  .thumb {
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-size: cover;
    background-position: center;

    .hover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($color__grey-darker, 0.5);
      opacity: 0;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }
    }

    .custom-checkbox,
    .custom-checkbox-no-purple  {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    .ctas {
      position: absolute;
      right: calc-rem(16);
      bottom: calc-rem(8);

      .card-cta {
        position: relative;
        width: calc-rem(28);
        height: calc-rem(28);
        display: block;
        margin-bottom: 8px;
        border-radius: 50%;
        background-color: $color__white;
        cursor: pointer;

        &--orange {
          background-color: $color__orange;
        }

        .icon {
          @extend .abs-center;
          width: 18px;
          height: 18px;
        }
      }

      &__library {
        bottom: 0;
      }
    }

    .media-stats {
      position: absolute;
      bottom: 16px;
      left: 16px;
      display: block;
      color: $color__white;
    }

    video {
      @extend .abs-center;
      width: 100%;
      min-width: 100%;
      height: auto;
      min-height: 100%;
    }

    .icon-diapo {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 24px;
      height: 24px;
    }
  }

  .content {
    line-height: 1.6;

    .description {
      width: calc(100% - 8px);
      word-break: break-word;
      @include ellipsis(1.6em, 3, $color__grey-dark);
    }
  }

  // library
  .media-inner-ctas {
    button {
      color: $color__white;
      padding: 0;
      background: none;
      border: none;
      font-family: $font_secondary;
      font-size: calc-rem(10);
      letter-spacing: 1.67px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
