.no-list-styles {
  @include normalize-list();
  line-height: 0;
}

.no-button-styles {
  @include normalize-button();
}

a {
  @include normalize-link();
}