@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

@mixin cancel-sr-only {
  position: unset;
  width: unset;
  height: unset;
  margin: unset;
  padding: unset;
  overflow: unset;
  border: unset;
  clip: unset;
  pointer-events: unset;
}
