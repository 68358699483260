.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  flex-shrink: 0;
  width: calc-rem(120);
  height: 100vh;
  text-align: center;
  background-color: $color__grey-dark;
  border-right: 1px solid $color__grey-mid;

  .logo {
    height: $headerH;
    display: flex;
    height: 120px;
    justify-content: center;
    align-items: center;
  }

  &__item {
    display: block;
    margin-top: calc-rem(20);
    text-align: center;

    span {
      display: inline-block;
      margin-top: calc-rem(4);
      color: $color__grey-light;
      font-family: $font-secondary;
      font-size: calc-rem(12);
      line-height: 1.67;
      transition: color 0.2s;
      padding-left: calc-rem(4);
      padding-right: calc-rem(4);
    }

    &.is-active,
    &:hover {
      .main-nav__icon {
        fill: $color__pink;
      }

      span {
        color: $color__pink;
      }
    }
  }

  &__icon {
    display: block;
    fill: $color__grey-light;
    @extend .mx-auto;
    transition: fill 0.2s;
    transform: scale(0.8);
  }
}
