.page-library {
  .sticky-wrapper {
    top: $headerH;
  }
}

.strong-description {
  color: $color__pink;
}

.description-cat {
  margin: 1em 0 0 0;
}

.library-medias-list {
  &.grid {
    display: flex;
    flex-wrap: wrap;

    > li {
      align-items: stretch;
      width: calc(100% / 3 - 10px);
      margin-right: calc-rem(10);
      margin-bottom: calc-rem(10);

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: calc-rem(8);
    padding: calc-rem(24) calc-rem(12);
    background-color: $color__grey-dark;
    border-radius: 10px;

    > div {
      margin: 0 calc-rem(12);
    }

    .action_cb {
      align-self: center;
      width: auto;
    }

    .thumb {
      position: relative;
      width: calc-rem(160);
      height: calc-rem(160);
      align-self: center;
      flex-shrink: 0;
      border-radius: 10px;
      overflow: hidden;
      background-size: cover;
      background-position: center;

      video {
        @extend .abs-center;
        width: 100%;
        min-width: 100%;
        height: auto;
        min-height: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      
      .media-username {
        display: inline-block;
        margin-right: calc-rem(16);
        font-family: $font-secondary;
      }
      
      &--top {
        font-size: calc-rem(15);
        // line-height: 1.6;
        word-break: break-word;
        @include ellipsis(1.6em, 3, $color__grey-dark);

      }

      .media-inner-ctas {
        display: flex;
        // justify-content: space-between;
        color: $color__grey-light;
        font-size: calc-rem(13);
        line-height: 1;

        button {
          display: flex;
          align-items: center;
          color: $color__white;
          padding: 0;
          background: none;
          border: none;
          font-family: $font-secondary;
          font-size: calc-rem(10);
          letter-spacing: 1.67px;
          text-transform: uppercase;
          cursor: pointer;

          .icon {
            margin-right: calc-rem(7);
            fill: $color__grey-light;
          }

          &:not(:first-child) {
            margin-left: calc-rem(40);
            color: $color__grey-light;
          }
        }
      }
    }

    .ctas {
      width: calc-rem(280);
      flex-shrink: 0;

      .button {
        width: 100%;
        max-height: 40px;

        &:not(:first-child) {
          margin-top: calc-rem(20);
        }

        .icon {
          max-width: 20px;
          max-height: 20px;
        }
      }
    }
  }
}
