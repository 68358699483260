.checkboxes-module {
  position: relative;
  ul {
    margin-top: calc-rem(8);
  }

  li {
    position: relative;
    &:not(:first-child) {
      margin-top: calc-rem(16);
    }
  }
}
