.sorting {
  color: $color__grey-light;
  font-size: calc-rem(15);
  
  &-label {
    //
  }

  &-pagination {
    &__item {
      margin-left: 5px;
      font-family: $font-secondary;
      cursor: pointer;
      transition: color .2s;

      &.is-active,
      &:hover {
        color: $color__white;
      }

      &:not(:nth-child(2)) {
        &:before {
          content: '|';
          color: $color__grey-light;
          margin: 0 4px 0 -1px;
        }
      }
    }
  }

  &-by {
    margin-left: 24px;

    select {
      padding: 0;
      color: $color__grey-light;
      font-family: $font-secondary;
      font-size: calc-rem(15);
      background: none;
      border: none;
    }
  }
}