.page-messages {   
    /* &-messages {
        .page__title {
            margin-top: 0;
        }
     
        .sticky-wrapper {
            top: 158px;
            margin-top: 0;
            // padding-top: 0;
            padding-right: 0;
            padding-bottom: calc-rem(32);
            padding-left: 0;
        }
    } */

    .sticky-wrapper {
        // top: 158px;
        margin-top: 0;
        // padding-top: 0;
        padding-right: 0;
        padding-bottom: calc-rem(32);
        padding-left: 0;
    }

    .info {
        color: $color__red;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.messages-list {
    li {
        width: 100%;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-top: calc-rem(8);
        padding: calc-rem(32) calc-rem(40) calc-rem(32) calc-rem(24);
        color: $color__white;
        font-size: calc-rem(15);
        line-height: 1.6;
        background-color: $color__grey-dark;
        border-radius: 10px;

        &__date {
            max-width: calc-rem(150);
            white-space: nowrap;

            span {
                font-family: $font_secondary;
            }

            .icon {
                flex-shrink: 0;
                width: calc-rem(16);
                height: calc-rem(16);
                margin-right: calc-rem(8)
            }
        }

        &__content {
            width: 50%;
            max-width: calc-rem(600);
        }

        &__ctas {
            width: 13%;
            max-width: calc-rem(150);
            display: flex;
            justify-content: space-between;
        }
    }
}

.bordered-icon {
    position: relative;
    width: calc-rem(40);
    height: calc-rem(40);
    flex-shrink: 0;
    background: $color__black;
    border: 1px solid $color__grey;
    border-radius: 5px;
    transition: all .2s;
    cursor: pointer;

    .icon {
        @extend .abs-center;
        width: calc-rem(24);
        height: calc-rem(24);
        fill: $color__white;
        opacity: 1;
        transition: opacity .2s;

        &-color {
            opacity: 0;
        }
    }

    &.is-disabled {
        opacity: .3;
        pointer-events: none;
    }

    &:not(.is-disabled):hover {
        background: $color__white;
        border-color: $color__white;

        .icon {
            opacity: 0;
            &-color {
                opacity: 1;
            }
        }
    }
}