@keyframes assetAnim {
    0% {
      opacity: 0;
      // transform: translateY(100%);
    }
  
    100% {
      opacity: 1;
      // transform: translateY(0);
    }
}

@keyframes openModal {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }

  @keyframes openNotice {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .stagger-anim {
    > * {
        opacity: 0;
        animation: assetAnim .3s $easing__out--sine;
        animation-fill-mode: forwards;
    }

    @for $i from 1 through 100 {
        &:nth-child(#{$i}) {
            > * {
                animation-delay: 100ms * $i;
            }
        }
    }
  }