.sr-only,
.fake-hidden {
  @include sr-only();
}

.no-scroll-y {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.icon {
  fill: $color__grey-light;

  @each $name, $color in $colors {
    &#{&}--#{$name} {
      fill: $color;
    }
  }

  &--reversed {
    transform: rotate(-180deg);
  }

  &--rotated {
    transform: rotate(-90deg);
  }
}

// use it with js-full-height-mobile
// useful for calculations w/ IOS bottom bar
.full-height-mobile {
  @include max-responsive(md) {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

.flag {
  font-size: calc-rem(20);
  vertical-align: sub;
}

/* SIMPLEBAR OVERRIDES */
.simplebar-track {
  width: 8px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: $color__grey-ultralight;
    transform: translateX(-50%);
  }
}

.simplebar-scrollbar:before {
  background-color: $color__pink !important;
}

.link {
  &--underline {
    text-decoration: underline;
  }
}
