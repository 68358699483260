.file-upload {
  &__container {
    @extend .list-reset;
    @extend .mt-6;
  }

  &__item {
    display: flex;
    align-items: center;
    font-family: $font-primary;
    letter-spacing: normal;
    color: $color__grey-darker;
    font-size: calc-rem(13);
    line-height: 1.6;
    white-space: 'break-spaces';
    text-align: left;

    width: calc-rem(300);

    @include responsive(md) {
      font-size: calc-rem(12);
    }
  }

  &__sublabel {
    font-family: $font-primary;
    letter-spacing: normal;
    color: $color__grey-light;
    font-size: calc-rem(13);
    line-height: 1.6;
    white-space: 'break-spaces';
    text-align: center;
  }

  &__delete {
    @extend .button;
    padding: 10px 15px;
    background-color: unset;
    cursor: pointer;
  }
}
