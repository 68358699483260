[class^="page-"]{
    /* display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh; */

    >.section {
        opacity: 0;
    }
}

.page {
    &__content {
        flex-grow: 1;
        padding: calc-rem(32) calc-rem(40);
    }

    &__title {
        margin-top: calc-rem(57);
        font-size: calc-rem(32);
        line-height: 1;
    }

    &__intro {
        max-width: calc-rem(495);
        font-size: calc-rem(18);
        line-height: 1.56;
    }
}