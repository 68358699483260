/* .pagination {
    &-bt {
        width: calc-rem(24);
        height: calc-rem(24);
        flex-shrink: 0;
        cursor: pointer;

        &.is-disabled {
            // display: none;
        }

        .icon {
            width: calc-rem(24);
            height: calc-rem(24);
        }

        &--prev {
            transform: rotate(180deg);
        }
    }

    &-indice {
        position: relative;
        width: calc-rem(32);
        height: calc-rem(32);
        flex-shrink: 0;
        // margin: 0 calc-rem(16);
        color: $color__white;
        font-size: calc-rem(16);
        line-height: calc-rem(35);
        text-align: center;
        border-radius: 50%;
        cursor: pointer;

        &.is-active {
            background-image: linear-gradient(225deg, $color__pink, $color__orange);
        }
    }
}
 */
.ant-pagination {
    &-item {
        width: calc-rem(32);
        height: calc-rem(32);
        background-color: transparent;
        border: none;
        border-radius: 50%;

        &-active,
        &:hover {
            background-image: linear-gradient(225deg, $color__pink, $color__orange);

            a {
                color: $color__white;
            }
        }

        a {
            color: $color__white;
            font-size: calc-rem(16);
            line-height: calc-rem(32);
            text-align: center;
            padding: 0;
        }

        &-ellipsis {
            color: $color__white !important;
        }

        &-link {
            background-color: transparent !important;
            border: none !important;

            .anticon {
                color: $color__white;
            }
        }

        &-link-icon {
            svg {
                color: $color__white;
                fill: $color__white;
            }
        }
    }
}