.page-login {   
    .login-grid {
        height: calc(100vh - 100px);
        
        .row {
            height: 100%;
        }
    }

    .title {
        max-width: 440px;
        font-family: $font-secondary;
        font-size: calc-rem(32);
        line-height: 1.2;

        .text-gradient {
            white-space: nowrap;
        }
    }

    button {
        display: inherit;
    }

    .shape-left,
    .shape-right {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
    }

    .shape-left {
        width: 579px;
        height: 547px;
        right: 15vw;
    }

    .shape-right {
        width: 499px;
        height: 549px;
        left: 15vw;
    }

    /* label {
       display: block;
    } */
}