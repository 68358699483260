.sort-bar {
  margin-top: calc-rem(36);

  &__item {
    display: flex;

   /*  &:not(:first-child, :last-child) {
      justify-content: center;
    } */
    

    &__label {
      display: flex;
      @extend .sup-title;
      @extend .sup-title--XS;
      @extend  .sup-title--grey-light;
    }

    .icon {
      margin-left: calc-rem(4);
      transition: transform .2s $easing__in-out--quad;
    }

    &[data-sort-by] {
      cursor: pointer;
      &:hover {
        color: $color__grey;
      }
    }

    &.is-sorting {
      .icon {
        transform: rotate(180deg);
      }
    }
  }
}