$color__border: #CCCCCC;
$color__landing-grey: #979797;
$color__landing-like: #f28482;

.landing {
  background-color: $color__white;
  color: $color__black;
  min-height: 100vh;
  font-family: $font-averta;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    @include responsive-landing(md) {
      margin-bottom: 3rem;
    }
  }

  &__banner {
    padding-top: 25%;
    position: relative;
    margin-top: -2rem;
    margin-bottom: 1.5rem;

    @include max-responsive-landing(xl) {
      margin: -2rem -2rem 1.5rem;
    }

    @include max-responsive-landing(md) {
      margin: -1rem -1rem 1rem;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: center;
      padding: 0 .75rem;
      transform: translate(-50%, -50%);
      font-size: bold;
      font-size: 3rem;
      color: $color__white;
      text-shadow: 1px 1px 4px rgba($color__black, .1);
      line-height: 1;
      z-index: 2;

      @include responsive-landing(lg) {
        font-size: 4rem;
      }
    }
  }

  &__inner {
    max-width: calc-rem(1280);
    margin: 0 auto;
    padding: 1rem;

    @include responsive-landing(md) {
      padding: 2rem;
    }
  }

  &__sidebar-container {
    @include responsive-landing(lg) {
      display: flex;
    }
  }

  &__logo {
    max-height: calc-rem(40);
    max-width: calc-rem(150);
    object-fit: contain;

    @include responsive-landing(md) {
      max-height: calc-rem(75);
      max-width: calc-rem(280);
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 400;
    color: var(--landing-accent-color);
    line-height: 1.2;
    padding-bottom: .5rem;

    &:last-child {
      padding-bottom: 1rem;
    }

    @include responsive-landing(md) {
      font-size: 2.5rem;
      padding-bottom: .75rem;

      &:last-child {
        padding-bottom: 1.25rem;
      }
    }
  }

  &__intro {
    font-size: calc-rem(18);
    padding-bottom: 1.5rem;

    a {
      color: var(--landing-accent-color);
    }

    @include responsive-landing(lg) {
      padding-bottom: 2rem;
    }
  }

  p, h1 {
    margin: 0;
  }
}

.landing-button {
  display: inline-block;
  background-color: var(--landing-button-bg-color);
  color: $color__white;
  border: 1px solid  var(--landing-button-bg-color);
  padding: .75rem 1rem;
  font-size: 1rem;
  line-height: 1.2;
  border-radius: var(--landing-button-radius);
  text-align: center;
  transition: .4s ease-in-out background-color, .4s ease-in-out color;

  @include responsive-landing(md) {
    min-width: calc-rem(264);
    font-size: calc-rem(18);
    padding: 1.125rem 2rem;
  }

  &--medium {
    padding: .5rem 1rem;
    margin-top: .75rem;
    min-width: 0;
  }

  &--small {
    padding: .5rem 1rem;
    margin-top: .75rem;
    font-size: calc-rem(13);
    min-width: calc-rem(180);
    max-width: 100%;
  }

  &:hover {
    color: var(--landing-button-bg-color);
    background-color: $color__white;
  }
}

.landing-wall {
  list-style-type: none;
  padding: 0;
  column-gap: .75rem;

  @include responsive-landing(md) {
    column-count: 2;
  }

  @include responsive-landing(lg) {
    column-count: 3;
  }

  &--hidden {
    opacity: 0;
  }

  &__loader {
    width: 3rem;
    height: 3rem;
    border: 5px solid var(--landing-accent-color);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: block;
    box-sizing: border-box;
    animation: loader 1s linear infinite;
    margin: 0 auto!important;
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &--sidebar {
    @include responsive-landing(md) {
      column-count: 2;
    }

    @include responsive-landing(xl) {
      column-count: 3;
    }
  }

  &__item {
    position: relative;
    margin-bottom: .75rem;
    overflow: hidden;
    box-shadow: 0 0 .5rem 0 rgba($color__black, .1);

    &:hover .landing-wall__hover {
      transform: translateY(0);
    }

    img {
      width: 100%;
    }
  }

  &__hover {
    position: absolute;
    background-color: $color__white;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    transform: translateY(100%);
    transition: .2s ease-in-out transform;
  }

  button {
    appearance: none;
    border: none;
    padding: 0;
    background: transparent;
    text-align: left;
    cursor: pointer;
    width: 100%;
  }
}

.landing-sidebar {
  width: calc-rem(232);
  flex-shrink: 0;
  margin-left: 2.5rem;
  color: var(--landing-accent-color);
  font-size: .75rem;

  &__inner {
    &.is-bottomed {
      position: fixed;
      bottom: 1rem;
    }

    &.is-fixed {
      position: fixed;
      top: 1rem;
    }
  }

  @include max-responsive-landing(lg) {
    margin-left: 0;
    width: 100%;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: .75rem;
    }
  }

  @include responsive-landing(xl) {
    width: calc-rem(292);
    margin-left: calc-rem(96);
  }

  ul {
    padding-left: 0;
    list-style-type: none;
  }

  &__title {
    font-family: $font-averta;
    color: var(--landing-accent-color);
    font-size: 2rem;
    line-height: 1.2;
    padding-top: .5rem;
  }

  &__pic {
    width: 100%;
    padding-top: 66%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__item:not(:last-child) {
    @include responsive-landing(lg) {
      margin-bottom: 5rem;
      position: relative;

      &:after {
        content: '';
        width: calc-rem(72);
        border-bottom: 1px solid $color__border;
        position: absolute;
        bottom: -2.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__item {
    @include max-responsive-landing(lg) {
      width: calc(33.33% - .5rem);
    }

    @include max-responsive-landing(md) {
      width: calc(50% - 6px);
    }

    @include max-responsive-landing(sm) {
      width: 100%;
    }
  }
}

.landing-product-sidebar {
  &__title {
    font-size: calc-rem(18);
    line-height: 1.2;
    padding-top: .75rem;
  }

  &__stars {
    display: inline-flex;
    margin-left: .25rem;
    position: relative;
    top: -.125rem;

    svg {
      width: .75rem;
    }
  }

  &__tag {
    display: inline-block;
    color: $color__white;
    background-color: var(--landing-accent-color);
    padding: 0 .5rem;
    border-radius: .25rem;
    font-size: calc-rem(10);
  }
}

.landing-infos {
  display: flex;
  align-items: center;
  font-size: calc-rem(13);
  line-height: 1;
  padding: .75rem;

  a {
    color: $color__black;
  }

  svg {
    flex-shrink: 0;
  }

  &__picto {
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 2px;
  }

  &__like {
    fill: $color__landing-like;
    width: calc-rem(18);
  }

  &__date {
    color: $color__landing-grey;
    font-size: calc-rem(10);
    padding-top: .25rem;
  }

  &__picto {
    margin-right: .5rem;
  }

  &__right {
    position: relative;
    padding-left: 1.5rem;
    gap: .25rem;

    &:before {
      content: '';
      position: absolute;
      left: .75rem;
      top: 50%;
      transform: translateY(-50%);
      height: 1rem;
      border-right: 1px solid $color__border;
    }
  }

  &__comment {
    margin-left: .25rem;
  }
}

.landing-modal {
  position: fixed;
  inset: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  &__powered {
    font-size: .5rem;
    padding-bottom: .5rem;
    text-align: right;

    a {
      color: currentColor;
      text-decoration: underline;
    }
  }

  &__bg {
    position: absolute;
    inset: 0;
    background-color: rgba($color__black, .4);
    transition: .4s ease-in-out opacity;
    opacity: 0;
  }

  &__inner {
    position: relative;
    background-color: #FFF;
    min-height: calc-rem(450);
    max-height: calc(100% - 2rem);
    max-width: 980px;
    opacity: 0;
    transform: translateY(-2rem);
    overflow: hidden;
    transition: .2s ease-in-out opacity, .4s ease-in-out transform;

    @media screen and (min-width: 1024px) and (min-height: 810px) {
      max-height: calc-rem(700);
    }

    @include responsive-landing(lg) {
      display: flex;
    }
  }

  &__txt-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem;

    @include responsive-landing(lg) {
      width: 50%;
    }
  }

  &__chevron {
    border: none;
    background: transparent;
    padding: 0;
    display: block;
    margin: 0 auto;
    width: 1.5rem;
    cursor: pointer;

    &--open {
      transform: scaleY(-1);
    }
    
    svg {
      width: 1rem;
      stroke: $color__border;
      display: inline-block;
    }
  }

  &__products {
    border-top: 1px solid $color__border;
    padding: 1.25rem 0 3rem;
    margin-top: .5rem;
  }

  &__txt {
    padding-top: 1.25rem;

    &--cut {
      position: relative;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; 
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba($color__white, 0) 0%, $color__white 100%);
        height: 2.5rem;
      }
    }
  }

  &__top {
    width: 100%;
    border-bottom: 1px solid $color__border;
    padding-left: 0;
  }

  &__close {
    position: absolute;
    top: .5rem;
    right: .5rem;
    padding: 0;
    background: transparent;
    appearance: none;
    border: none;
    z-index: 5;
    cursor: pointer;

    svg {
      width: calc-rem(24);
    }

    @include max-responsive(lg) {
      background-color: $color__white;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  &__picture {
    background-color: $color__black;
    overflow: hidden;
    text-align: center;

    img,
    video {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @include responsive-landing(lg) {
      width: 50%;
    }
  }

  &__content {
    overflow: auto;
    max-height: calc(100vh - 4rem);

    @include responsive-landing(lg) {
      display: flex;
    }
  }

  &.is-visible {
    .landing-modal {
      &__bg {
        opacity: 1;
      }

      &__inner {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.landing-product {
  line-height: 1.2;

  @include max-responsive-landing(md) {
    text-align: center;
    width: calc-rem(292);
    margin: 0 auto;

    .landing-product__title {
      padding-top: .5rem;
    }
  }

  &--single {
    text-align: center;
    width: calc-rem(292);
    margin: 0 auto;

    .landing-product__title {
      padding-top: .5rem;
    }
  }

  &--multiple {
    &:not(:last-child) {
      border-bottom: 1px solid $color__border;
      padding-bottom: 1.25rem;
      margin-bottom: 1.25rem;
    }

    @include responsive-landing(md) {
      display: flex;
      align-items: flex-start;
    }
  }

  &__left {
    @include responsive-landing(md) {
      width: calc-rem(192);
      flex-shrink: 0;
      margin-right: .75rem;
    }
  }

  &__pic {
    width: 100%;
    padding-top: 66%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: calc-rem(18);
    color: var(--landing-accent-color);
    padding-bottom: .25rem;
  }
}