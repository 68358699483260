@use 'sass:math';

.selection-wrapper {
  .collections-list__item {
    max-width: 170px;
    pointer-events: none;

    .overlay {
      display: none;
    }
  }
}

.sidebar__content {
  > div.input-copy-paste {
    margin-top: 0;
    button.button--plain {
      padding-top: calc-rem(2);
      padding-bottom: 0;
      padding-left: calc-rem(10);
      padding-right: calc-rem(10);
      border-top-left-radius: 0%;
      border-bottom-left-radius: 0%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    input {
      border-top-right-radius: 0%;
      border-bottom-right-radius: 0%;
    }
  }
}

.selection-cta {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-family: $font-secondary;
  font-size: calc-rem(10);
  letter-spacing: 1.67px;
  text-transform: uppercase;
  cursor: pointer;

  .picto {
    position: relative;
    width: calc-rem(16);
    height: calc-rem(16);
    flex-shrink: 0;
    margin-right: calc-rem(8);
    background: $color__red-orange;
    border-radius: 50%;

    .icon {
      @extend .abs-center;
      width: calc-rem(8);
      height: calc-rem(8);
    }
  }
}

.widget-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: calc-rem(8);
  padding: calc-rem(30);
  color: $color__white;
  font-size: calc-rem(15);
  background-color: $color__grey-dark;
  border-radius: 10px;
  transition: background 0.2s;

  &:hover {
    color: $color__white;
    background-color: $color__grey-mid;
  }

  > span {
    display: inline-block;
    line-height: normal;

    &:first-child,
    &:last-child {
      width: calc(#{percentage(math.div(1, 3))} - 30px);
    }

    &:last-child {
      text-align: right;
    }
  }
}
