html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: $color__black;

  /* Avoid Chrome to see Safri hack */
  @supports (-webkit-touch-callout: none) {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}

body {
  overflow-x: hidden;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a.info, a.info:visited, span.info {
  color: $color__pink;
  line-height: 1.4;
  cursor: pointer;

  &:hover {
    color: $color__orange;
    text-decoration: underline;
  }
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

picture {
  img {
    width: 100% !important;
  }
}
