@font-face {
  font-weight: normal;
  font-family: 'Vremena Grotesk Book';
  font-style: normal;
  src: local('Vremena Grotesk Book'), url('../../fonts/vremena-grotesk-cufonfonts-webfont/VremenaGroteskBook.woff') format('woff');
}

@font-face {
  font-weight: normal;
  font-family: 'Vremena Grotesk Medium';
  font-style: normal;
  src: local('Vremena Grotesk Medium'), url('../../fonts/vremena-grotesk-cufonfonts-webfont/VremenaGroteskMedium.woff') format('woff');
}

@font-face {
  font-weight: normal;
  font-family: 'Averta';
  font-style: normal;
  src: local('Averta'), url('../../fonts/averta/averta-regular-webfont.woff') format('woff');
}

@font-face {
  font-weight: normal;
  font-family: 'Dear Joe';
  font-style: normal;
  src: local('Dear Joe'), url('../../fonts/dearJoe/dearJoe.woff') format('woff');
}

$font-primary: 'Vremena Grotesk Book', sans-serif;
$font-secondary: 'Vremena Grotesk Medium', sans-serif;
$font-averta: 'Averta', sans-serif;
$font-joe: 'Dear Joe', sans-serif;
$font-size__base: 16;

html {
  font-size: #{$font-size__base}px;
}

body {
  color: $color__white;
  font-family: $font-primary;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// primary font declaration
.font-body {
  font-family: $font-primary, sans-serif;
}

.wf-active .font-body {
  font-family: $font-primary;
}

.text-book {
  font-family: $font-primary;
}

.text-medium {
  font-family: $font-secondary;
}

.gen-text {
  font-size: calc-rem(18);
  font-family: $font-primary;
  line-height: 1.33;
  letter-spacing: normal;

  &--med {
    font-family: $font-secondary;
  }

  &--XS {
    font-size: calc-rem(18);

    @include responsive(md) {
      font-size: calc-rem(15);
    }
  }

  &--S {
    font-size: calc-rem(18);
    line-height: 1.33;

    @include responsive(md) {
      font-size: calc-rem(18);
    }
  }

  &--M {
    font-size: calc-rem(24);

    @include responsive(md) {
      font-size: calc-rem(24);
    }
  }

  &--L {
    font-size: calc-rem(24);
    line-height: 1.33;

    @include responsive(md) {
      font-size: calc-rem(32);
      line-height: 1.33;
    }
  }

  &--XL {
    font-size: calc-rem(32);
    line-height: 0;

    @include responsive(md) {
      font-size: calc-rem(43);
    }
  }

  @each $name, $color in $colors {
    &#{&}--#{$name} {
      color: $color;
    }
  }
}

// titles

.title {
  font-size: calc-rem(40);
  font-family: $font-primary;
  line-height: 1;
  letter-spacing: normal;

  @include responsive(md) {
    font-size: calc-rem(80);
    line-height: normal;
  }

  &--XL {
    // font-size: calc-rem(80);
    font-size: calc-rem(56);

    @include responsive(md) {
      font-size: calc-rem(160);
    }
  }

  @each $name, $color in $colors {
    &#{&}--#{$name} {
      color: $color;
    }
  }
}

.sup-title {
  font-size: calc-rem(10);
  font-family: $font-secondary;
  line-height: 1.6;
  letter-spacing: 1.67px;
  text-transform: uppercase;

  @include responsive(md) {
    font-size: calc-rem(16);
    line-height: 1;
    letter-spacing: 4px;
  }

  &--M {
    font-size: calc-rem(12);
    line-height: 1.33;
    letter-spacing: 3px;
  }

  &--S {
    font-size: calc-rem(11);
    line-height: 1.45;
    letter-spacing: 2.75px;
  }

  &--XS {
    font-size: calc-rem(10);
    line-height: 1.6;
    letter-spacing: 1.67px;
  }

  @each $name, $color in $colors {
    &#{&}--#{$name} {
      color: $color;
    }
  }
}

.text-gradient {
  background: linear-gradient($color__pink, $color__orange);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &--H {
    display: inline-block;
    background: linear-gradient(to right, $color__orange, $color__pink, $color__white 50% );
    // background: linear-gradient(90deg, $color__white 100%, $color__orange 50%, $color__pink 50%);
    // background-position: 100% center;
    background-size: 400% auto;
    -webkit-background-clip: text;

    &.is-animated {
      -webkit-text-fill-color: transparent;
      animation: shine .7s $easing__out--sine;
    }
  }
}

@keyframes shine {
  0% {
    background-position: 100% center;
  }

  100% {
    background-position: 0% center;
  }
}

