/* @tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
 */
// MIXINS
@import 'base/breakpoints';
@import 'mixins/responsive';
@import 'mixins/normalize';
@import 'mixins/sr-only';
@import 'mixins/ie11';
@import 'mixins/ellipsis';

// UTILS
@import 'utils/easings';
@import 'utils/resets';
@import 'utils/calc-rem';

// BASE
@import 'base/vars';

@import 'base/colors';
@import 'base/typography';
@import 'utils/tailwind';
@import 'base/animations';
@import 'base/core';
@import 'base/common';

// GRID
@import 'grid/config';
@import 'grid/layout';

// LAYOUT
@import 'layout/layout';

// COMPONENTS
@import 'components/alert';
@import 'components/buttons';
@import 'components/checkboxes-module';
@import 'components/hashtags-list';
@import 'components/file-input';
@import 'components/filters';

// @import 'components/aspect-ratio';
@import 'components/modals';
@import 'components/notices';
// @import 'components/header';
@import 'components/inputs';
@import 'components/list-displayer';
@import 'components/loader';
// @import 'components/form';
// @import 'components/accordion';
@import 'components/search-module';
@import 'components/select-module';
@import 'components/sort-bar';
@import 'components/sorting';
@import 'components/stickyWrapper';
@import 'components/navigation/headerShowcase';
@import 'components/navigation/main-nav';
@import 'components/navigation/pageHeader';
@import 'components/navigation/pagination';
@import 'components/navigation/sidebar';
@import 'components/theme-button';

// SLICES
@import 'sections/section';

// PAGES
@import 'pages/page';
@import 'pages/account';
@import 'pages/ask-form';
@import 'pages/collections';
@import 'pages/login';
@import 'pages/feeds';
@import 'pages/feeds-contents';
@import 'pages/feeds-settings';
@import 'pages/messages';
@import 'pages/library';
@import 'pages/welcome';
@import 'pages/widgets';
@import 'pages/mobile-blocker';
@import 'pages/landing';

// VENDORS
@import 'vendors/swiper';
@import '~antd/dist/antd.css';
