.list-reset {
  @include normalize-list();
  line-height: 0;
}

.hidden {
  display: none;
}

.w-full {
  width: 100%;
}

.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-10 {
  font-size: calc-rem(10);
}

.text-18 {
  font-size: calc-rem(18);
}

.text-24 {
  font-size: calc-rem(24);
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.self-end {
  align-self: flex-end;
}

.flex-col {
  flex-direction: column;
}

.gap-1em {
  gap: 1rem;
}

.grow {
  flex-grow: 1;
}

.lx-hidden {
  @include responsive(lx) {
    display: none;
  }
}

.md-flex-row {
  @include responsive(md) {
    flex-direction: row;
  }
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.md-mt-6 {
  @include responsive(md) {
    margin-top: 1.5rem;
  }
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.md-mt-10 {
  @include responsive(md) {
    margin-top: 2.5rem;
  }
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-0-important {
  padding-top: 0rem !important;
}

.md-mb-8 {
  @include responsive(md) {
    margin-bottom: 2rem;
  }
}

.md-mb-10 {
  @include responsive(md) {
    margin-bottom: 2.5rem;
  }
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.md-mt-10 {
  @include responsive(md) {
    margin-top: 2.5rem;
  }
}

.md-mt-12 {
  @include responsive(md) {
    margin-top: 3rem;
  }
}

.md-mt-200 {
  @include responsive(md) {
    margin-top: 12.5rem;
  }
}

.md-hidden {
  @include responsive(md) {
    display: none;
  }
}

.md-mt-0 {
  @include responsive(md) {
    margin-top: 0;
  }
}

.leading-0 {
  line-height: 0;
}
