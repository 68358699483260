@mixin normalize-layout() {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
}
  
@mixin normalize-list() {
  margin: 0;
  padding: 0;
  list-style: none;
}
  
@mixin normalize-table() {
  border-collapse: collapse;
  border-spacing: 0;
}
  
@mixin normalize-input() {
  background: none;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
}
  
@mixin normalize-quote() {
  quotes: none;

  &::before,
  &::after {
    content: "";
    content: none;
  }
}
  
@mixin normalize-link() {
  text-decoration: none;
}
  
@mixin normalize-font() {
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
  
@mixin normalize-button() {
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  background: none;
  border: 0;
}

@mixin normalize-focus() {
  &:focus {
    outline: none;
  }
}