.pageHeader {
    position: sticky;
    top: 0;
    min-height: $headerH;
    z-index: 2;
    padding: 1.25rem 2rem;
    border-bottom: solid 1px $color__grey-mid;
    background-image: linear-gradient(to bottom, $color__grey-dark, $color__black);

    &__breadcrumb {
        align-items: center;
        li {
            position: relative;

            &:not(:first-child, .no-chevron) {
                &:before {
                    content: '〉';
                    margin-right: calc-rem(16);
                    margin-left: calc-rem(16);
                    color: $color__grey-light;
                    font-family: $font-secondary;
                    font-size: 18px;
                    font-weight: bold;
                    vertical-align: super;
                }
            }

            >a, >span {
                position: relative;
                color: $color__white;
                font-family: $font-primary;
                font-size: calc-rem(32);
                line-height: 1;
                letter-spacing: normal;
            }

            a {
                color: $color__grey-light;
                transition: color .2s;

                &:hover {
                    color: $color__grey-lighter;
                }
            }

            .status {
                flex-wrap: nowrap;
                font-size: calc-rem(15);
                white-space: nowrap;
                margin-left: 1rem;

                .date {
                    font-family: $font-secondary;
                }
            }
        }
    }
    
    &__tabs {
        // margin-top: calc-rem(47);
        margin-bottom: -3rem;
        
        li {
            &:not(:first-child) {
                margin-left: calc-rem(40)
            }

            a {
                position: relative;
                height: calc-rem(30);
                display: block;
                font-size: calc-rem(11);
                line-height: 1.45;
                letter-spacing: 2px;
                color: $color__grey-light;
                text-transform: uppercase;
                transition: .2s color;

                &:hover, &.is-active {
                    color: $color__white;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 0;
                    background-color: $color__white;;
                }

                &.is-active {
                    font-family: $font-secondary;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: calc-rem(3);
                    }
                }
            }
        }
    }

    .user-account-cta {
        .img {
            width: 40px;
            height: 40px;
            margin-right: calc-rem(16);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-color: $color__white;
            // overflow: hidden;
            border-radius: 50%;
            box-shadow: 0 0 6px 4px rgba($color__white, 0.2);
        }

        span {
            color: $color__white;
        }
    }
}