.page-welcome {   
    .grip {
        font-family: $font-secondary;
        font-size: calc-rem(32);
    }

    .welcome-steps {
        position: relative;
        display: flex;
        align-items: center;

        > div {
            position: relative;

            &:nth-child(1) {
                width: calc-rem(81);
                height: calc-rem(81);
                flex-shrink: 0;
                margin-right: calc-rem(40);
                border: 1px solid $color__white;
                border-radius: 50%;

                span {
                    @extend .abs-center;
                }
            }

            &:nth-child(2) {
                font-size: calc-rem(18);
                line-height: 1.4;

                span {
                    display: block;
                    color: $color__grey-lighter;
                }
            }
        }

        &:not(:first-child) {            
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 40px;
                width: 1px;
                height: 40px;
                transform: translateY(-100%);
                background-color: $color__white;
            }
        }
    }
}